import { default as checking_45accountBJIMN9pFILMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/checking-account.vue?macro=true";
import { default as homeMfO7w9aKm6Meta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/home.vue?macro=true";
import { default as indexOXyda3tdLUMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/index.vue?macro=true";
import { default as mission8vcqSTW4MqMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/mission.vue?macro=true";
import { default as not_45dataNoj9NbYCFBMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/not-data.vue?macro=true";
import { default as onboardbwHBFQG3jqMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/onboard.vue?macro=true";
import { default as partnersai3DVsU5OyMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/partners.vue?macro=true";
import { default as shoppWdwrhzr7eMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/shop.vue?macro=true";
import { default as tierpcaW9hrFYXMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/tier.vue?macro=true";
import { default as total_45gumTObccUzMcMMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/total-gum.vue?macro=true";
import { default as walletGHe6Xm8XBwMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/wallet.vue?macro=true";
import { default as welcome2CkEPHWcSJMeta } from "/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/welcome.vue?macro=true";
export default [
  {
    name: checking_45accountBJIMN9pFILMeta?.name ?? "checking-account",
    path: checking_45accountBJIMN9pFILMeta?.path ?? "/checking-account",
    meta: checking_45accountBJIMN9pFILMeta || {},
    alias: checking_45accountBJIMN9pFILMeta?.alias || [],
    redirect: checking_45accountBJIMN9pFILMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/checking-account.vue").then(m => m.default || m)
  },
  {
    name: homeMfO7w9aKm6Meta?.name ?? "home",
    path: homeMfO7w9aKm6Meta?.path ?? "/home",
    meta: homeMfO7w9aKm6Meta || {},
    alias: homeMfO7w9aKm6Meta?.alias || [],
    redirect: homeMfO7w9aKm6Meta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexOXyda3tdLUMeta?.name ?? "index",
    path: indexOXyda3tdLUMeta?.path ?? "/",
    meta: indexOXyda3tdLUMeta || {},
    alias: indexOXyda3tdLUMeta?.alias || [],
    redirect: indexOXyda3tdLUMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/index.vue").then(m => m.default || m)
  },
  {
    name: mission8vcqSTW4MqMeta?.name ?? "mission",
    path: mission8vcqSTW4MqMeta?.path ?? "/mission",
    meta: mission8vcqSTW4MqMeta || {},
    alias: mission8vcqSTW4MqMeta?.alias || [],
    redirect: mission8vcqSTW4MqMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/mission.vue").then(m => m.default || m)
  },
  {
    name: not_45dataNoj9NbYCFBMeta?.name ?? "not-data",
    path: not_45dataNoj9NbYCFBMeta?.path ?? "/not-data",
    meta: not_45dataNoj9NbYCFBMeta || {},
    alias: not_45dataNoj9NbYCFBMeta?.alias || [],
    redirect: not_45dataNoj9NbYCFBMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/not-data.vue").then(m => m.default || m)
  },
  {
    name: onboardbwHBFQG3jqMeta?.name ?? "onboard",
    path: onboardbwHBFQG3jqMeta?.path ?? "/onboard",
    meta: onboardbwHBFQG3jqMeta || {},
    alias: onboardbwHBFQG3jqMeta?.alias || [],
    redirect: onboardbwHBFQG3jqMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/onboard.vue").then(m => m.default || m)
  },
  {
    name: partnersai3DVsU5OyMeta?.name ?? "partners",
    path: partnersai3DVsU5OyMeta?.path ?? "/partners",
    meta: partnersai3DVsU5OyMeta || {},
    alias: partnersai3DVsU5OyMeta?.alias || [],
    redirect: partnersai3DVsU5OyMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: shoppWdwrhzr7eMeta?.name ?? "shop",
    path: shoppWdwrhzr7eMeta?.path ?? "/shop",
    meta: shoppWdwrhzr7eMeta || {},
    alias: shoppWdwrhzr7eMeta?.alias || [],
    redirect: shoppWdwrhzr7eMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/shop.vue").then(m => m.default || m)
  },
  {
    name: tierpcaW9hrFYXMeta?.name ?? "tier",
    path: tierpcaW9hrFYXMeta?.path ?? "/tier",
    meta: tierpcaW9hrFYXMeta || {},
    alias: tierpcaW9hrFYXMeta?.alias || [],
    redirect: tierpcaW9hrFYXMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/tier.vue").then(m => m.default || m)
  },
  {
    name: total_45gumTObccUzMcMMeta?.name ?? "total-gum",
    path: total_45gumTObccUzMcMMeta?.path ?? "/total-gum",
    meta: total_45gumTObccUzMcMMeta || {},
    alias: total_45gumTObccUzMcMMeta?.alias || [],
    redirect: total_45gumTObccUzMcMMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/total-gum.vue").then(m => m.default || m)
  },
  {
    name: walletGHe6Xm8XBwMeta?.name ?? "wallet",
    path: walletGHe6Xm8XBwMeta?.path ?? "/wallet",
    meta: walletGHe6Xm8XBwMeta || {},
    alias: walletGHe6Xm8XBwMeta?.alias || [],
    redirect: walletGHe6Xm8XBwMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/wallet.vue").then(m => m.default || m)
  },
  {
    name: welcome2CkEPHWcSJMeta?.name ?? "welcome",
    path: welcome2CkEPHWcSJMeta?.path ?? "/welcome",
    meta: welcome2CkEPHWcSJMeta || {},
    alias: welcome2CkEPHWcSJMeta?.alias || [],
    redirect: welcome2CkEPHWcSJMeta?.redirect,
    component: () => import("/Users/giangtuan98/Documents/Code/BekiSoft/Projects/Gumart/Code/gumart-fe/pages/welcome.vue").then(m => m.default || m)
  }
]